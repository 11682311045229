<style lang="scss">
.exp-bg {
  height: 246px;
  padding: 15px 20px;
  background: url("../assets/images/exp-bg.png");
  background-size: cover;
}

.exp-spent {
  font-size: 28px;
  font-weight: bold;
  color: #F8E71C;
  padding-left: 8px;
  letter-spacing: 2px;
}

.exp-status {
  font-size: 18px;
  font-weight: bold;
  color: #8FD918;
  flex: 1;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.exp-status-dot {
  width: 8px;
  height: 8px;
  background: #8FD918;
  border-radius: 4px;
  margin-right: 5px;
  display: block;
}

.exp-title {
  font-size: 18px;
  font-weight: bold;
  color: #FFFFFF;
}

.exp-title-line {
  display: flex;
  align-items: center;
  //justify-content: center;
}

.exp-spent-line {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
}

.exp-info-line {
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  margin-top: 8px;
  display: flex;
  align-items: center;
}

.exp-info-right {
  flex: 1;
  text-align: right;
}

.exp-infos {
  margin-top: 26px;
}

.finish-btn {
  width: 265px;
  height: 36px;
  background: #FF4158;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
  color: #FFFFFF;
  border: 0;
  margin-top: 10px;
}

.exp-wrapper {
  //position: absolute;
  //left: 0;
  //top: 46px;
  margin-left: -20px;
  margin-right: -20px;
  padding: 20px;
  background: #fff;
}

.exp-line-title {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}

.exp-line-status {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
}

.exp-line-status-circle {
  width: 6px;
  height: 6px;
  background: #999999;
  border-radius: 3px;
  margin-right: 4px;
}

.applier {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  margin-bottom: 15px;
}

.do-score {
  font-size: 14px;
  color: #4A89DC;
}

.view-score {
  font-size: 14px;
  color: #F86719;
}

.confirm-finish {
  width: 80%;
  left: 10%;
  background: #fff;
}

.finish-panel {
  .panel-title {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: 0 15px;
  }

  .panel-content {
    padding: 15px;
  }
}

</style>
<style lang="scss" scoped>
.app-header {
  height: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  //border-bottom: solid 1px rgba(0, 0, 0, 0.03);
  //box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.08);
  z-index: 2;

  font-size: 16px;
  color: #000;
  font-weight: bold;
}

.header-left, .header-right {
  height: 100%;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

}

.header-left {
  left: 0;
}

.header-right {
  right: 0;
}

.icon-left {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
}

.go-home {
  display: block;
  width: 21px;
  height: 19px;
  background: url("../assets/images/go-home.png");
  background-size: contain;
}

</style>
<template>
  <div>
<!--    <AppHeader title="实验列表"/>-->
    <div class="app-header" >
      实验列表
      <div class="header-left" @click="goHome()"><i class="iconfont icon-prev"></i></div>
      <div class="header-right" @click="goHome()"><i class="go-home"></i></div>
    </div>
    <div class="exp-wrapper" v-if="this.curExp">
      <div class="exp-bg">
        <div class="exp-title-line">
          <div class="exp-title">{{ curExp.equipmentId }}</div>
          <div class="exp-status"><span class="exp-status-dot"></span>实验中</div>
        </div>
        <div class="exp-spent-line">已实验<span class="exp-spent">{{ curExp.spent }}</span></div>

        <div class="exp-infos">
          <div class="exp-info-line">
            <div class="exp-info-left">实验人：{{ curExp.userName }}</div>
            <div class="exp-info-right">实验地点：{{ curExp.eqLocation }}</div>
          </div>
          <div class="exp-info-line">
            上机时间：{{ formatDate(curExp.beginAt) }}
          </div>
          <div class="exp-info-line">
            预约时间：{{ beginEnd(curExp.booking.beginAt, curExp.booking.endAt) }}
          </div>
        </div>
        <div class="flex-center">
          <button class="finish-btn" :disabled="loadFlag" @click="endExp(curExp.id)">结束实验</button>
        </div>
      </div>
    </div>

    <div slot="content" v-infinite-scroll="loadExps"
         infinite-scroll-disabled="loading"
         infinite-scroll-distance="10">
      <div class="line" v-for="(exp,idx) of exps">
        <div class="flex-dir-row exp-line-title">
          {{ exp.equipmentId }}
          <div class="flex-item-auto exp-line-status">
            <div class="exp-line-status-circle"></div>
            <div v-if="exp.endAt!=null">已结束</div>
            <div v-else>实验中</div>
          </div>
        </div>
        <div class="applier">申请人：{{ exp.userName }}</div>
        <div>实验起止时间: {{ beginEnd(exp.beginAt, exp.endAt) }}</div>
        <div class="flex-dir-row">
          实验时长: {{ displaySpent(exp.spent) }}
          <div class="flex-item-auto text-right">
            <div class="view-score" @click="viewScore(exp)" v-if="exp.score>0">已评价</div>
            <div class="do-score" @click="doScore(exp)" v-else>评价</div>
          </div>
        </div>
      </div>
      <div class="line text-center" v-if="exps.length===0">暂无记录</div>
    </div>

    <mt-popup
        v-model="popupVisible"
        position="right"
        class="confirm-finish"
        v-if="curExp!=null"
    >
      <Panel class="finish-panel">
        <div slot="title">
          结束实验
        </div>
        <div slot="content">
          <div>确定要结束当前实验？</div>
          <mt-switch style="margin-top: 10px" v-model="finalAccounts" v-if="curExp.allowRelease">
            尽量释放{{ this.$moment(curExp.booking.endAt).format('HH:mm') }}之前的实验时间
          </mt-switch>
          <div class="grid gap10" style="margin: 20px -10px -10px;">
            <div class="grid-cell-1-2">
              <div class="btn btn-small green" v-if="curExp.blueToothId" @click="doEndTwo(curExp.id)">蓝牙确定结束</div>
              <div class="btn btn-small green" v-else @click="doEnd(curExp.id)">确定结束</div>
            </div>
            <div class="grid-cell-1-2">
              <div class="btn btn-small normal" @click="cancelEnd()">取消</div>
            </div>
          </div>
        </div>
      </Panel>
    </mt-popup>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import {InfiniteScroll} from 'mint-ui';
import {Toast, MessageBox} from 'mint-ui';
import Panel from "@/components/Panel";

let intervalId
export default {
  components: {
    Panel,
    AppHeader,
    InfiniteScroll
  },
  data() {
    return {
      page: 1,
      exps: [],
      loading: false,
      noMore: false,
      curExp: null,
      popupVisible: false,
      finalAccounts: false,
      isNff: false,
      blueToothTypeShow:false,
      endFlag: false,
      loadFlag: false
    }
  },
  mounted() {
    this.initExps()
  },
  methods: {
    initExps() {
      this.curExp = null
      this.exps = []
      this.page = 1
      this.noMore = false
      this.loadExps()
    },
    loadExps() {
      if (this.noMore) {
        return
      }
      const that = this
      var eqId = this.$route.query.equipmentId
      var bookingId = this.$route.query.bookingId
      console.log("eqId",eqId)
      console.log("bookingId",bookingId)
      this.loading = true
      let data = new FormData()
      data.append('page', `${this.page}`)
      data.append('limit', '20')
      this.$http.post(`/api/experiment/list`, data).then(res => {
        if (res.data && res.data.length > 0) {
          if (!that.curExp) {

            if(eqId){
              this.queryexperimentByeqId(eqId,bookingId)
            }

            // if (intervalId) {
            //   window.clearInterval(intervalId)
            // }
            // let latestExp = res.data[0]
            // console.log("latestExp",latestExp)
            //
            // if (latestExp.endAt <= 0 ) {
            //   that.curExp = res.data.shift()
            //   that.curExp.spent = that.displaySpent(that.$moment().valueOf() - that.curExp.beginAt)
            //   intervalId = window.setInterval(function () {
            //     that.curExp.spent = that.displaySpent(that.$moment().valueOf() - that.curExp.beginAt)
            //   }, 1000)
            // }
          }

          const exps = res.data
          for (var i=0;i<exps.length;i++){
            if(exps[i].endAt==null){

            }
          }
          that.exps = that.exps.concat(exps)
          that.loading = false
          that.page = that.page + 1
        } else {
          this.noMore = true
        }
      })
    },
    beginEnd(beginAt, endAt) {
      let beginYMD = this.$moment(beginAt).format('YYYY.MM.DD')
      let result = this.$moment(beginAt).format('YYYY.MM.DD HH:mm')
      if (endAt) {
        let endYMD = this.$moment(endAt).format('YYYY.MM.DD')
        if (endYMD == beginYMD) {
          result += ("-" + this.$moment(endAt).format('HH:mm'))
        } else {
          result += ("-" + this.$moment(endAt).format('YYYY.MM.DD HH:mm'))
        }
      } else {
        result += "-"
      }
      return result
    },
    displaySpent(spent) {
      let totalSeconds = Math.floor(spent / 1000)
      let hours = Math.floor(totalSeconds / 3600)
      let minutes = Math.floor((totalSeconds - hours * 3600) / 60)
      let seconds = totalSeconds - hours * 3600 - minutes * 60
      return this.fillZero(hours) + ':' + this.fillZero(minutes) + ':' + this.fillZero(seconds)
    },
    formatDate(date) {
      return this.$moment(date).format('YYYY.MM.DD HH:mm')
    },
    fillZero(num) {
      if (num < 10) {
        return '0' + num;
      } else {
        return num;
      }
    },
    viewScore(exp) {
      this.$router.push({
        path: '/exp-score',
        query: {
          id: exp.id,
          view: true
        }
      })
    },
    doScore(exp) {
      this.$router.push({
        path: '/exp-score',
        query: {
          id: exp.id
        }
      })
    },
    doEnd(id){
      console.log("结束ID",id)
      const that = this
      this.$http.post(`/api/experiment/end?id=${id}&finalAccounts=${that.finalAccounts}&operationByUser=true`).then(res => {
        if(res.success){
          that.initExps()
        }
      })
    },
    doEndTwo(id) {
      console.log("结束ID", id)
      const that = this
      var eqId = this.$route.query.equipmentId
      const fullPath=encodeURIComponent(that.$route.fullPath)
      this.$http.get('/api/booking/regisBiz?equipmentId=' + eqId + "&bizOperator=close&bookingId="+this.$route.query.bookingId+"&fullPath="+fullPath).then(res => {
        if (res.success && res.data && res.data.scheme) {
          that.scheme = res.data.scheme
          window.location.href = that.scheme
          let bizId=res.data.bizId

          this.$http.post(`/api/booking/getBizStatus?experimentId=${id}&finalAccounts=${that.finalAccounts}&operationByUser=true&bizId=`+bizId).then(res => {})

          that.popupVisible = false
          let count=0;
          let timer=setInterval(()=>{
            that.getBizLastStatus(bizId,id);
            count++;
            this.loadFlag=true
            if (that.endFlag || count>10){
              clearInterval(timer)
              this.loadFlag=false
              if (count>10){
                setTimeout(()=>{
                  Toast('抱歉，蓝牙连接失败，请检查微信位置、微信附近的设备和蓝牙是否开启。确认开启后，请重新扫码或尝试其他设备');
                },1000)
              }
            }
          }, 3000);

        } else {
          Toast('数据获取失败');
        }
      })
    },
    getBizLastStatus(bizId,id){
      console.log("结束ID", id)
      if (!this.endFlag){
        this.$http.get('/api/booking/getBizLastStatus?bizId='+bizId+'&bizOperator=close').then(response =>{
          if(response.success && response.data.machineLastState=='0'){
            this.endFlag=true
            this.$router.push("/")
          }
        })
      }

    },
    endExp() {
      const that = this
      that.popupVisible = true
    },
    cancelEnd(){
      this.popupVisible = false
    },
    queryexperimentByeqId(eqId,bookingId){
      const that = this
      if (intervalId) {
        window.clearInterval(intervalId)
      }
      this.$http.post(`/api/experiment/infoByEquipmentId?equipmentId=`+eqId+"&bookingId="+bookingId).then(res=>{
        console.log("res.experiment",res)

        if(res.data && res.data.length > 0){

          let latestExp = res.data[0]
          console.log("endAt",latestExp.endAt)
          if (latestExp.endAt <= 0) {
            console.log("正在试验")
            that.curExp = latestExp
            console.log("that.curExp2",that.curExp)
            that.curExp.spent = that.displaySpent(that.$moment().valueOf() - that.curExp.beginAt)
            intervalId = window.setInterval(function () {
              that.curExp.spent = that.displaySpent(that.$moment().valueOf() - that.curExp.beginAt)
            }, 1000)
          }
        }


      })
    },
    getEquipInfo(eqId){
      this.$http.get('/api/equipment/info?equipmentId='+eqId+'&bizOperator=close').then(res =>{
        if (res.equipment.blueToothType){
          this.blueToothTypeShow=true
        }
      })

    },
    goHome() {
      this.$router.push("/")
    }
  }

}
</script>
